.ant-select-selection {
  border: none;
  background-color: transparent;

}
.ant-select-item {
  color:  transparent; /* Option text color */
}

.ant-select-dropdown {
  background-color: transparent; /* Dropdown background */
}
