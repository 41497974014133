@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'),
    url('fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratExtraBold';
  src: local('MontserratExtraBold'),
    url('fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'),
    url('fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'),
    url('fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'),
    url('fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratLight';
  src: local('MontserratLight'),
    url('fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratItalic';
  src: local('MontserratItalic'),
    url('fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-display: swap;
}
